import { useEffect, useState } from "react";

import intl from "react-intl-universal";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";

import Loader from "@components/Loader";

import useToast from "@hooks/useToast";

import {
  FIRESTORE_COLLECTIONS,
  QUESTION_STATUS,
  TEST_SECTION_TYPE
} from "@utils/config";
import { db } from "@utils/firebase";
import theme from "@utils/theme";

import Question from "@interfaces/database/Question";
import AttentionToDetail from "@pages/Candidates/Test/AttentionToDetail";
import CulturalMatch from "@pages/Candidates/Test/CulturalMatch";
import EmotionalIntelligence from "@pages/Candidates/Test/EmotionalIntelligences";
import PriorityWork from "@pages/Candidates/Test/PriorityWork";
import SocialStyle from "@pages/Candidates/Test/SocialStyle";
import SubjectiveThinking from "@pages/Candidates/Test/SubjectiveThinking";
import TestCompleted from "@pages/Candidates/Test/TestCompleted";

const TestSections = () => {
  const { active_section: activeSection } = useParams();
  const [questions, setQuestions] = useState([]);
  const [isQuestionsLoading, setIsQuestionsLoading] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    try {
      const fetchQuestions = async () => {
        setIsQuestionsLoading(true);
        const questionsRef = collection(
          db,
          FIRESTORE_COLLECTIONS.CANDIDATE_QUESTIONS
        );
        const questionQuery = query(
          questionsRef,
          where("status", "==", QUESTION_STATUS.ACTIVE)
        );

        const questionData: any = []; // eslint-disable-line @typescript-eslint/no-explicit-any
        const querySnapshot = await getDocs(questionQuery);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            questionData.push({
              id: doc.id,
              ...doc.data()
            });
          });
        }
        setQuestions(questionData);
        setIsQuestionsLoading(false);
      };
      fetchQuestions();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  }, []);

  if (isQuestionsLoading) {
    return <Loader />;
  }

  const culturalMatchQuestions = questions.filter(
    (singleQuestion: Question) =>
      singleQuestion?.test_section_type === TEST_SECTION_TYPE.CULTURAL_MATCH
  );
  const socialStyleQuestions = questions.filter(
    (singleQuestion: Question) =>
      singleQuestion?.test_section_type === TEST_SECTION_TYPE.SOCIAL_STYLE
  );
  const emotionalIntelligenceQuestions = questions.filter(
    (singleQuestion: Question) =>
      singleQuestion?.test_section_type === TEST_SECTION_TYPE.SOCIAL_STYLE
  );
  const subjectiveThinkingQuestions = questions.filter(
    (singleQuestion: Question) =>
      singleQuestion?.test_section_type ===
      TEST_SECTION_TYPE.SUBJECTIVE_THINKING
  );
  const attentionToDetailQuestions = questions.filter(
    (singleQuestion: Question) =>
      singleQuestion?.test_section_type ===
      TEST_SECTION_TYPE.ATTENTION_TO_DETAIL
  );

  let currentActiveForm = <CulturalMatch questions={culturalMatchQuestions} />;

  switch (Number(activeSection)) {
    case 0:
      currentActiveForm = <CulturalMatch questions={culturalMatchQuestions} />;
      break;
    case 1:
      currentActiveForm = <SocialStyle questions={socialStyleQuestions} />;
      break;
    case 2:
      currentActiveForm = (
        <SubjectiveThinking questions={subjectiveThinkingQuestions} />
      );
      break;
    case 3:
      currentActiveForm = (
        <EmotionalIntelligence questions={emotionalIntelligenceQuestions} />
      );
      break;
    case 4:
      currentActiveForm = (
        <AttentionToDetail questions={attentionToDetailQuestions} />
      );
      break;
    case 5:
      currentActiveForm = <PriorityWork />;
      break;
    case 6:
      currentActiveForm = <TestCompleted />;
      break;
    default:
      currentActiveForm = <CulturalMatch questions={culturalMatchQuestions} />;
      break;
  }

  return (
    <>
      <Box
        bgcolor={theme.palette.background.default}
        borderRadius="10px"
        px={1.25}>
        {currentActiveForm}
      </Box>
    </>
  );
};

export default TestSections;
